import { changeUrlQuery } from './url'

export function imageUrl(imageUrl: string, width?: number | 'auto', quality?: number): string {
  if (!imageUrl) {
    return ''
  }

  if (imageUrl.startsWith('data:')) {
    return imageUrl
  }

  return changeUrlQuery(imageUrl, {
    w: width,
    q: quality || 95,
    auto: 'format'
  })
}
